import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import AlbumsInfo from "../components/albums/albums-info.js"
import AlbumsList from "../components/albums/albums-list.js"

const Albums = () => {
    /**
     * ql_albums: Contains the list of all Wet Ink albums
     * plus the individual albums descriptions based on
     * page slug
     */
    const ql_albums = useStaticQuery(graphql`
        query {
            # List of albums displayed on the left side of page
            allContentfulAlbum(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        title
                        artist
                        slug
                        date
                        albumCover {
                            file {
                                url
                            }
                        }
                        url
                        description {
                            json
                        }
                    }
                }
            }
        }
    `)
    return (
        <Layout>
            <SEO title="Albums" />
            <AlbumsInfo
                albumData={
                    ql_albums.allContentfulAlbum.edges[0].node.description.json
                }
                albumTitle={ql_albums.allContentfulAlbum.edges[0].node.title.toUpperCase()}
            />
            <AlbumsList albumData={ql_albums.allContentfulAlbum.edges} />
        </Layout>
    )
}

export default Albums
