import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Style from "./albums.module.scss"

/**
 * AlbumsInfo (Default): On-Click info for
 * albums
 */
const AlbumsInfo = props => {
  return (
    <div className={Style.albumsInfoPane}>
      <h2>{props.albumTitle}</h2>
      <hr />
      {documentToReactComponents(props.albumData)}
    </div>
  )
}

export default AlbumsInfo
