import React from "react"
import { Link } from "gatsby"

import Style from "./albums.module.scss"

/**
 * AlbumsList (Default): List of all albums
 * produced by and featuring Wet Ink
 */
const AlbumsList = props => {
  return (
    <div className={Style.albumsListPane}>
      <h2>ALBUMS</h2>
      <hr />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          gridTemplateRows: "auto",
          gridGap: "1%",
        }}
      >
        {props.albumData.map((album, id) => {
          return <Album albumData={album.node} key={id} />
        })}
      </div>
    </div>
  )
}

export default AlbumsList

/* =================================================== */

/**
 * Album: A single album entry
 */
const Album = props => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Link to={`/albums/${props.albumData.slug}`}>
        <img
          src={`http:${props.albumData.albumCover.file.url}`}
          className="image"
          align="left"
          alt={props.albumData.title}
        />
        <div className={Style.middle}>
          <div className={Style.text}>
            <br />
            <br />
            <b>
              {props.albumData.title.toUpperCase()}
              <br />
              ———
              <br />
              {props.albumData.artist.toUpperCase()}
            </b>
          </div>
        </div>
      </Link>
    </div>
  )
}
